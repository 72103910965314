@font-face {
  font-family: "Apoc";
  font-feature-settings: "liga" on, "swsh" on, "kern" on;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: 100;
  src: url("/static/fonts/Apoc-Revelations.woff") format("woff");
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "Sneak";
  font-feature-settings: "liga" on, "swsh" on, "kern" on;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: 300;
  src: url("/static/fonts/Sneak-Regular.woff") format("opentype");
  text-rendering: optimizeLegibility;
}
body {
  background-color: #000000;
  margin: 0;
  padding: 0;
}

.tab {
  background-color: #000000;
  background-image: url(/static/img/white.svg);
  background-repeat: round;
  height: 70px;
  position: fixed;
  width: 100%;
  z-index: 3;
}
@media (min-width: 600px) {
  .tab {
    height: 100%;
    position: fixed;
    width: 70px;
  }
}

.tab img {
  margin-bottom: -10px;
}

.content {
  background-color: #dddddd;
  max-width: none;
  padding-top: 70px;
}
@media (min-width: 600px) {
  .content {
    max-width: 1480px;
    padding-left: 70px;
    padding-top: 0;
  }
}

.page__content {
  background-color: #dddddd;
  max-width: none;
  padding-left: 0;
}
@media (min-width: 600px) {
  .page__content {
    max-width: 1480px;
    padding-left: 70px;
  }
}

.home__thumb-group {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
}

.home__thumb-group a {
  color: #000000;
  text-decoration: none;
}

.home__thumb {
  background-color: #000000;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 50%;
}
@media (min-width: 600px) {
  .home__thumb {
    width: 50%;
  }
}
@media (min-width: 1080px) {
  .home__thumb {
    width: 33.333%;
  }
}

.home__thumb-image {
  height: 100%;
  position: relative;
  z-index: 2;
}

.home__thumb:hover img {
  opacity: 0;
}

.home__thumb a {
  cursor: pointer;
}

.hide {
  display: none;
}
@media (min-width: 1080px) {
  .hide {
    display: inline;
  }
}

.home__thumb-thumbnail {
  height: 100%;
  max-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 100%;
}

.home__thumb-group h2 {
  font-family: "Apoc", "Times New Roman", Times, serif;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  padding: 20px 10px;
}
@media (min-width: 350px) {
  .home__thumb-group h2 {
    font-size: 22px;
  }
}
@media (min-width: 450px) {
  .home__thumb-group h2 {
    font-size: 28px;
  }
}
@media (min-width: 600px) {
  .home__thumb-group h2 {
    font-size: 30px;
    padding: 30px;
  }
}
@media (min-width: 768px) {
  .home__thumb-group h2 {
    font-size: 40px;
  }
}
@media (min-width: 960px) {
  .home__thumb-group h2 {
    font-size: 50px;
  }
}
@media (min-width: 1080px) {
  .home__thumb-group h2 {
    font-size: 40px;
  }
}
@media (min-width: 1300px) {
  .home__thumb-group h2 {
    font-size: 49px;
  }
}

.home__thumb-group h4 {
  bottom: 0;
  display: block;
  font-family: "Sneak", sans-serif;
  font-feature-settings: "liga" on, "swsh" on, "kern" on;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  font-weight: 100;
  line-height: 1.4;
  margin: 0;
  padding: 0 0 20px 20px;
  text-rendering: optimizeLegibility;
  text-transform: uppercase;
}
@media (min-width: 450px) {
  .home__thumb-group h4 {
    font-size: 14px;
  }
}
@media (min-width: 450px) {
  .home__thumb-group h4 {
    font-size: 18px;
  }
}
@media (min-width: 600px) {
  .home__thumb-group h4 {
    display: block;
    padding: 0 0 30px 30px;
  }
}
@media (min-width: 1300px) {
  .home__thumb-group h4 {
    display: block;
    font-size: 20px;
  }
}

.logo-stamp {
  bottom: 0;
  display: block;
  padding: 0 20px 20px 0;
  position: absolute;
  right: 0;
  width: 10vw;
}
@media (min-width: 450px) {
  .logo-stamp {
    width: 10vw;
  }
}
@media (min-width: 600px) {
  .logo-stamp {
    padding: 0 20px 20px 0;
    width: 8vw;
  }
}
@media (min-width: 960px) {
  .logo-stamp {
    padding: 0 30px 30px 0;
    width: 6rem;
  }
}

.about:hover,
.about:hover h2,
.about:hover h4 {
  color: #ffffff;
}

.about:hover {
  background-color: #000000;
}

.about img {
  -webkit-filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(28deg) brightness(98%) contrast(105%);
          filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(28deg) brightness(98%) contrast(105%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.about:hover img {
  -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(69deg) brightness(104%) contrast(104%);
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(69deg) brightness(104%) contrast(104%);
  opacity: 100%;
}

.home__article {
  background-color: rgba(0, 0, 0, 0);
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
}

.home__article-group {
  -webkit-box-align: stretch;
          align-items: stretch;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  height: 100%;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.bg {
  background-color: #000000;
}

.xr {
  background-color: #fd6e5f;
}

.sharefile {
  background-color: #8bdf94;
}

.the-nation {
  background-color: #e8192b;
}

.ibm-techxchange {
  background-color: #1b6afe;
}

.reaktor {
  background-color: #e6fe46;
}

.turf {
  background-color: #695ff5;
}

.art-direction {
  background-color: #9b4cee;
}

.arboretum {
  background-color: #69e261;
}

.commonwealth-fusion {
  background-color: #cf6d46;
}

.about {
  background-color: #f5a5ba;
}

.upstatement {
  background-color: #00bc02;
}

.experimentation-site {
  background-color: #7671ff;
}

.sasaki {
  background-color: #f8574b;
}

.jesus-and-mary-chain {
  background-color: #2175ff;
}

.japan-cloud {
  background-color: #a4c2d3;
}

.visual-nonsense {
  background-color: #ff00a3;
}

.punk-chronicles {
  background-color: #cdff00;
}

.reading {
  background-color: #ffc700;
}

.page__container {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
}
@media (min-width: 600px) {
  .page__container {
    padding: 0 30px;
  }
}

.page__header {
  margin-left: 16.6%;
}

.page__top-nav a,
.page__top-nav p {
  font-family: "Sneak", sans-serif;
  font-size: 18px;
  font-weight: 100;
  text-decoration: none;
  text-transform: uppercase;
}

.page__top-nav {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  padding-top: 100px;
  vertical-align: bottom;
}
@media (min-width: 600px) {
  .page__top-nav {
    padding-top: 30px;
  }
}

.page__back-button {
  max-width: 100%;
  position: fixed;
  z-index: 1;
}

.page__back-button:hover {
  border-bottom: 1px solid #000000;
}

.page__back-button a {
  color: #000000;
}

.page__small-title {
  margin-left: 50%;
}
@media (min-width: 600px) {
  .page__small-title {
    margin-left: 33.3%;
  }
}

.page__small-title p {
  margin: 0;
}

.page__title h1 {
  font-family: "Apoc", serif;
  font-size: 45px;
  font-weight: 100;
}
@media (min-width: 600px) {
  .page__title h1 {
    font-size: 60px;
  }
}
@media (min-width: 768px) {
  .page__title h1 {
    margin-left: 16.6%;
  }
}

.page__title h1 a {
  color: #0b00ff;
  text-decoration: none;
}

.page__header p {
  font-family: "Sneak", sans-serif;
  font-size: 18px;
  font-weight: 100;
}

.page__description {
  color: #000000;
}

.page__description,
.page__project-link {
  margin-bottom: 40px;
  margin-right: 0;
}
@media (min-width: 768px) {
  .page__description,
.page__project-link {
    margin-left: 33.333%;
  }
}
@media (min-width: 1080px) {
  .page__description,
.page__project-link {
    margin-right: 16.6%;
  }
}

.page__description,
.page__description h3,
.page__about-description p,
.page__project-link,
.page__project-link p {
  font-family: "Sneak", sans-serif;
  font-size: 20px;
  font-weight: 100;
  line-height: 1.5;
}

.page__description p,
.page__description h3,
.page__about-description p {
  margin: 0;
}

.page__description p {
  text-indent: 5%;
}

.page__about-description p,
.page__project-link a {
  padding-top: 30px;
  text-decoration: none;
  text-indent: 0;
}

.page__description a,
.page__description a:visited,
.page__project-link a,
.page__project-link a:visited {
  background-color: #0b00ff;
  color: #dddddd;
}

.page__description a,
.page__about-description a,
.page__about-description a:visited,
.page__project-link a,
.page__project-link a:visited {
  color: #dddddd;
  opacity: 1;
  padding: 3px;
  text-decoration: none;
}

.page__description a:hover,
.page__project-link a:hover {
  background-color: #dddddd;
  color: #0b00ff;
  -webkit-transition: opacity 3s cubic-bezier(0, 0, 0.4, 1);
  transition: opacity 3s cubic-bezier(0, 0, 0.4, 1);
}

.page__project-link {
  margin-bottom: 40px;
}

.page__thumb-group {
  background-color: #000000;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.page__thumb-group a {
  color: #000000;
  margin-left: 0;
  text-decoration: none;
}
.page__thumb {
  background-color: #000000;
  position: relative;
  -webkit-transition: border-radius 0.5s;
  transition: border-radius 0.5s;
}
.page__thumb img {
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: border-radius 0.5s;
  transition: border-radius 0.5s;
  width: 100%;
}

.page__thumb a {
  cursor: pointer;
}

.page__thumb:hover {
  border-radius: 100%;
}

.page__thumb-group {
  margin-left: 0;
}
.page__thumb-group h2 {
  font-family: "Apoc", "Times New Roman", Times, serif;
  font-feature-settings: "liga" on, "swsh" on, "kern" on;
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  margin: 0;
  text-rendering: optimizeLegibility;
}
@media (min-width: 450px) {
  .page__thumb-group h2 {
    font-size: 28px;
  }
}
@media (min-width: 600px) {
  .page__thumb-group h2 {
    font-size: 35px;
  }
}
@media (min-width: 1300px) {
  .page__thumb-group h2 {
    font-size: 60px;
  }
}

.page__thumb-group h4 {
  bottom: 0;
  display: none;
  font-family: "Sneak", sans-serif;
  font-size: 20px;
  font-weight: 300;
  margin: 0;
  padding: 0 40px 20px 10px;
  position: absolute;
}
@media (min-width: 600px) {
  .page__thumb-group h4 {
    display: block;
    font-size: 18px;
    padding: 0 40px 20px 20px;
  }
}
@media (min-width: 1300px) {
  .page__thumb-group h4 {
    display: block;
    font-size: 20px;
    padding: 0 60px 30px 30px;
  }
}

.page__article {
  background-color: rgba(0, 0, 0, 0);
  height: 80vw;
  max-height: 650px;
  padding: 20px 10px;
}
@media (min-width: 600px) {
  .page__article {
    height: 64vw;
    padding: 30px;
  }
}
@media (min-width: 768px) {
  .page__article {
    height: 44vw;
  }
}
@media (min-width: 960px) {
  .page__article {
    height: 44vw;
  }
}
@media (min-width: 1080px) {
  .page__article {
    height: 44vw;
  }
}
@media (min-width: 1300px) {
  .page__article {
    height: 54vw;
  }
}

.page__more-projects {
  margin: 0;
  padding: 0;
}

.page__video-full a:visited,
.page__video-full a,
.page__image-full a:visited,
.page__image-full a,
.page__image-2-up a:visited,
.page__image-2-up a {
  background-color: #0b00ff;
  color: #dddddd;
  padding: 2px;
  text-decoration: none;
}

.page__video-full a:hover,
.page__image-full a:hover,
.page__image-2-up a:hover {
  background-color: #dddddd;
  color: #0b00ff;
  text-decoration: none;
}

.page__caption p {
  font-family: "Sneak", sans-serif;
  font-size: 14px;
  font-weight: 100;
  letter-spacing: 0.6px;
  line-height: 1.5;
}

.page__body {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
}

.page__image-full {
  margin-bottom: 25px;
  margin-left: 0;
}
@media (min-width: 768px) {
  .page__image-full {
    margin-bottom: 40px;
    margin-left: 16.6%;
  }
}

.page__image-bleed {
  margin-bottom: 25px;
  max-width: 1480px;
  position: relative;
  width: 100%;
}
@media (min-width: 600px) {
  .page__image-bleed {
    margin-bottom: 40px;
  }
}

.page__image-full img,
.page__image-2-up img {
  border-radius: 5px;
  width: 100%;
}

.page__image-bleed img {
  width: 100%;
}

.page__video-full video {
  border-radius: 5px;
  width: 100%;
}

.page__video-full {
  border-radius: 10px;
  display: block;
  margin-bottom: 25px;
  margin-left: 0;
  position: relative;
}
@media (min-width: 768px) {
  .page__video-full {
    margin-bottom: 40px;
    margin-left: 16.6%;
  }
}

.page__video-bleed video {
  border-radius: 5px;
  width: 100%;
}

.page__video-bleed {
  border-radius: 10px;
  display: block;
  margin-bottom: 25px;
  position: relative;
}
@media (min-width: 600px) {
  .page__video-bleed {
    margin-bottom: 40px;
  }
}

.page__image-2-up video {
  border-radius: 5px;
  width: 100%;
}

.page__image-2-up {
  display: block;
  margin-bottom: 25px;
  margin-left: 0;
  margin-right: 33.333%;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .page__image-2-up {
    margin-bottom: 40px;
    margin-left: 16.6%;
    margin-right: 40%;
  }
}

.content-about {
  background-color: #000000;
  max-width: none;
  padding-top: 70px;
}
@media (min-width: 600px) {
  .content-about {
    max-width: 1480px;
    padding-left: 70px;
    padding-top: 0;
  }
}

.page__content-about {
  background-color: #f5a5ba;
  max-width: none;
  padding-left: 0;
}
@media (min-width: 600px) {
  .page__content-about {
    max-width: 1480px;
    padding-left: 70px;
  }
}

.page__title-about h1 {
  margin: 50px 0 0;
}

.page__title-about p {
  margin-top: 0;
  text-indent: 5%;
}

.page__title-about,
.page__description-about {
  margin-left: 0;
}
@media (min-width: 600px) {
  .page__title-about,
.page__description-about {
    margin-left: 16.6%;
  }
}

.page__small-title-about {
  color: #000000;
}

.page__title-about h1 a,
.page__title-about p a {
  background-color: #0b00ff;
  color: #ffffff;
  text-decoration: none;
}

.page__title-about a:hover,
.page__project-link-about a:hover {
  color: #000000;
}

.page__title-about a:hover {
  background-color: #f5a5ba;
  color: #0b00ff;
  text-decoration: none;
}

.page__title-about h1,
.page__title-about p,
.page__description-about h1 {
  font-family: "Apoc", serif;
  font-feature-settings: "liga" on, "swsh" on, "kern" on;
  font-size: 35px;
  -webkit-font-smoothing: antialiased;
  font-weight: 100;
  letter-spacing: -0.05em;
  text-rendering: optimizeLegibility;
}
@media (min-width: 600px) {
  .page__title-about h1,
.page__title-about p,
.page__description-about h1 {
    font-size: 45px;
  }
}
@media (min-width: 768px) {
  .page__title-about h1,
.page__title-about p,
.page__description-about h1 {
    font-size: 50px;
  }
}
@media (min-width: 960px) {
  .page__title-about h1,
.page__title-about p,
.page__description-about h1 {
    font-size: 60px;
  }
}

.page__header-about p {
  font-family: "Apoc", serif;
  font-size: 26px;
  font-weight: 100;
}
@media (min-width: 600px) {
  .page__header-about p {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .page__header-about p {
    font-size: 30px;
  }
}
@media (min-width: 960px) {
  .page__header-about p {
    font-size: 30px;
  }
}
@media (min-width: 1080px) {
  .page__header-about p {
    font-size: 30px;
  }
}

.page__description-about-container-narrow {
  display: grid;
  grid-gap: 0 1rem;
  grid-template-columns: repeat(auto-fill, minmax(12.5%, 1fr));
  margin-bottom: 40px;
}

.page__description-about-container {
  display: grid;
  grid-gap: 0 1rem;
  grid-template-columns: repeat(auto-fill, minmax(12.5%, 1fr));
  margin-bottom: 40px;
}

.page__description-about-row-item {
  margin-top: 0;
}

.title {
  grid-column-end: 7;
  grid-column-start: 1;
  margin-bottom: 1rem;
}
@media (min-width: 450px) {
  .title {
    grid-column-end: 2;
    grid-column-start: 1;
    margin-bottom: 0;
  }
}

.full-column {
  grid-column-end: 7;
  grid-column-start: 1;
}
@media (min-width: 450px) {
  .full-column {
    grid-column-end: 7;
    grid-column-start: 3;
  }
}

.first-column {
  grid-column-end: 7;
  grid-column-start: 1;
}
@media (min-width: 350px) {
  .first-column {
    grid-column-end: 4;
    grid-column-start: 1;
  }
}
@media (min-width: 450px) {
  .first-column {
    grid-column-end: 7;
    grid-column-start: 3;
  }
}
@media (min-width: 960px) {
  .first-column {
    grid-column-end: 5;
    grid-column-start: 3;
  }
}

.second-column {
  grid-column-end: 7;
  grid-column-start: 1;
}
@media (min-width: 350px) {
  .second-column {
    grid-column-end: 7;
    grid-column-start: 4;
  }
}
@media (min-width: 450px) {
  .second-column {
    grid-column-end: 7;
    grid-column-start: 3;
  }
}
@media (min-width: 960px) {
  .second-column {
    grid-column-end: 7;
    grid-column-start: 5;
  }
}

.page__description-about,
.page__title-about {
  color: #000000;
}

.page__description-about,
.page__project-link-about {
  margin-bottom: 70px;
  margin-right: 0;
}
@media (min-width: 768px) {
  .page__description-about,
.page__project-link-about {
    margin-left: 33.333%;
  }
}
.page__description-about,
.page__description-about h3,
.page__about-description-about p,
.page__project-link-about,
.page__project-link-about p {
  font-family: "Sneak", sans-serif;
  font-feature-settings: "liga" on, "swsh" on, "kern" on;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  font-weight: 100;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
}

.page__description-about p,
.page__description-about h3,
.page__about-description-about p {
  margin: 0;
}

.page__description-about-row-item h3 {
  padding-bottom: 1rem;
}

.page__-description-about p,
.page__project-link-about a {
  padding-top: 35px;
  text-decoration: none;
  text-indent: 0;
}

.page__description-about a,
.page__description-about a:visited,
.page__project-link-about a,
.page__project-link-about a:visited {
  background-color: #0b00ff;
  color: #dddddd;
}

.page__description-about a,
.page__about-description-about a,
.page__about-description-about a:visited,
.page__project-link-about a,
.page__project-link-about a:visited {
  color: #dddddd;
  opacity: 1;
  padding: 3px;
  text-decoration: none;
}

.page__description-about a:hover,
.page__project-link-about a:hover {
  background-color: #f5a5ba;
  color: #0b00ff;
  -webkit-transition: opacity 3s cubic-bezier(0, 0, 0.4, 1);
  transition: opacity 3s cubic-bezier(0, 0, 0.4, 1);
}